import styled from "styled-components";
import { fonts } from "theme/fonts";

export const ButtonPrimary = ({
  as,
  href,
  className,
  onClick,
  target,
  rel,
  children,
  ...props
}) => {
  return (
    <Wrapper
      as={as}
      href={href}
      className={className}
      onClick={onClick}
      target={target}
      rel={rel}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border: 0px;
  width: fit-content;
  padding: 0 25px;
  background-color: #0f359b;
  color: #ffffff;
  font-family: "BrandonBld";
  outline: 0;
  cursor: pointer;
  white-space: nowrap !important;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  border-radius: 25px;

  &:hover {
    background-color: #322ffd;
  }
`;
