import { FieldMessage, Input } from "Components";

export const ZipCode = ({ onChange, value, field, error = {}, ...props }) => {
  const handleZip = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <Input
        type="tel"
        onChange={handleZip}
        value={value}
        placeholder={field.placeholder || "5-digit code..."}
        error={!!error.status}
        success={!error.status && value}
        name={field.label}
        aria-label={field.label}
        maxLength={5}
        {...props}
      />
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};
