import { useState, useReducer, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { reducer } from "Modules";
import { EventContext, UserContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";
import { handleErrors, Field } from "Components/Form";

export const RegistrationForm = ({
  formPart,
  formHeading,
  formLegal,
  submitButton,
  setIneligible,
  focus,
}) => {
  const { event } = useContext(EventContext);
  const { writeToUserSubmissionDoc } = useContext(UserContext);
  const { logLeadSubmission, logClick } = useContext(AnalyticsContext);
  const scrollRef = document.getElementById("First Name");

  useEffect(() => {
    if (focus && scrollRef) {
      scrollRef?.focus();
    }
  }, [focus]);

  const [registration, setRegistration] = useReducer(reducer, {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const fields = event.register.fields;

  const eligibleZipCodes = JSON.parse(event.register.zipCodes);
  const zips = eligibleZipCodes.map((item) => item.zip);

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  useEffect(() => {
    setSubmitting(false);
  }, [submitButton]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorObject = handleErrors(fields, registration, formPart);

    if (!errorObject) {
      setSubmitting(true);

      if (formPart === 0) {
        // check if zip code is eligible
        const zipCodeFound =
          zips.length === 0 ||
          zips.find((zip) => zip === parseInt(registration.ZIP));

        if (!zipCodeFound) return setIneligible(true);
      }

      registration.formPart = formPart;
      await writeToUserSubmissionDoc(registration);
      logLeadSubmission({ formPart, registration });
    } else {
      setSubmitting(false);
      setErrors(errorObject);
    }
  };

  return (
    <>
      {fields && (
        <RegForm onSubmit={handleSubmit}>
          <Form>
            {fields
              .sort((a, b) => a.order - b.order)
              .filter((field) => {
                return field.formPart === formPart;
              })
              .map((field, index) => {
                let mobileSize = "100%";
                let desktopSize = "97%";
                if (field.span === "half") desktopSize = "calc(48% - 2px)";

                if (
                  field.span !== "full" &&
                  (field.spanMobile === "half" || !field.spanMobile)
                )
                  mobileSize = "calc(48% - 4px)";

                return (
                  <FieldContainer
                    sizeDesktop={desktopSize}
                    sizeMobile={mobileSize}
                    fullSize={field.span === "full"}
                    key={index}
                    order={field.order}
                  >
                    {field.type !== "optIn" && !!field.label && (
                      <Label as="label" htmlFor={field.label}>
                        {field.label}
                      </Label>
                    )}
                    <Field
                      field={field}
                      value={registration[field.name]}
                      onChange={(value) => handleChange(value, field.name)}
                      // autoFocus={field.name === "FNAME" && focus}
                      error={{
                        status: errors[field.name],
                        message: errors[field.name]?.message,
                      }}
                    />
                  </FieldContainer>
                );
              })}

            {formHeading && formLegal && (
              <>
                <FormLegal>{ReactHtmlParser(formHeading)}</FormLegal>
                <FormLegal
                  aria-label="button to open link in a new window to read sweepstakes rules"
                  onClick={() =>
                    linkClickHandler(
                      "http://bit.ly/3Er5bTW",
                      "Sweepstakes Rules",
                      "internal"
                    )
                  }
                >
                  {ReactHtmlParser(formLegal)}
                </FormLegal>
              </>
            )}
          </Form>

          {submitting ? (
            <RegisterButton>Loading</RegisterButton>
          ) : (
            <RegisterButton
              type="submit"
              aria-label="button to register for sweepstakes with Chase Rice"
              onClick={() =>
                linkClickHandler("Register button", "Registration", "internal")
              }
            >
              {submitButton ? submitButton : "Submit"}
            </RegisterButton>
          )}
        </RegForm>
      )}
    </>
  );
};

const FormTitle = styled.div`
  margin: 12px 0;
  color: #000;
  font-size: 12px;
  font-weight: 300;
  order: 98;
  /* font-family: "AntennaLight"; */
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 20px;
  margin: 20px 0px;
  width: 100%;

  @media (max-width: 500px) {
    gap: 25px;
    row-gap: 25px;
  }
`;

const RegForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: 50px;
  @media ${(props) => props.theme.maxMedia.medium} {
    padding-bottom: 0;
    button {
      margin: 10px auto 0 0;
    }
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${(props) => props.sizeDesktop};
  ${(props) => props.order && `order: ${props.order};`}
  width: 100%;

  @media ${(props) => props.theme.maxMedia.medium} {
    flex: 0 1 ${(props) => props.sizeMobile};
  }
`;

const Label = styled.label`
  color: #4d4d4d;
  font-size: 14px;
  margin-bottom: 5px;
`;

const FormLegal = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: -7px 0 10px;
  order: 99;
  color: #4d4d4d;
  a,
  a:visited {
    color: #4d4d4d;
  }
`;

const RegisterButton = styled(ButtonPrimary)`
  margin-bottom: 10px;
  width: 40%;
  @media (max-width: 950px) {
    margin: 25px 0 25px 0;
    width: 50%;
    padding: 10px 0;
    font-size: 16px;
    align-self: center;
  }
`;
