import React, { forwardRef } from "react";
import styled from "styled-components";

export const RadioLabel = styled.label`
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 0 0 0 ${(props) => (props.hideCircle ? 0 : "27px")};
  margin: ${(props) =>
    props.hideCircle ? "2px 0" : props.doubleLine ? "7px 0" : "11px 0"};
`;

const HiddenRadio = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const StyledRadio = styled.svg`
  height: 14px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;

  #inner {
    transform: ${(props) => (props.checked ? "scale(1)" : "scale(0)")};
    transform-origin: center center;
    transition: all 0.3s ease;
  }

  input:disabled + & {
    #inner {
      fill: ${(props) =>
        props.checked
          ? props.theme.colors.checkboxDisabledCheckedFill
          : props.theme.colors.checkboxDisabledUncheckedFill};
      transform: ${(props) => (props.checked ? "scale(1)" : "scale(1.3)")};
    }
    #outer {
      stroke: ${(props) =>
        props.checked
          ? props.theme.colors.checkboxDisabledCheckedFill
          : props.theme.colors.checkboxDisabledPath};
    }
  }
`;

export const RadioTitle = styled.span`
  display: block;
  ${(props) =>
    !!props.headerFont ? props.theme.fonts.header : props.theme.fonts.default};
  font-weight: ${(props) =>
    !!props.headerFont ? "700" : props.checked ? "600" : "400"};
  font-size: 17px;
  line-height: 22px;
`;

export const RadioContent = styled.span`
  display: block;
  ${(props) => props.theme.fonts.default}
  font-size: ${(props) => (props.doubleLine ? "12px" : "17px")};
  font-weight: ${(props) => (!!props.bold ? "600" : "400")};
  line-height: 18px;
`;

export const IconContainer = styled.div`
  margin-bottom: 4px;
`;

export const Radio = forwardRef((props, ref) =>
  (({
    className,
    onChange,
    title,
    children,
    checked,
    disabled,
    icon,
    hideCircle,
    ...props
  }) => {
    const handleChange = (e) => {
      const {
        target: { checked },
      } = e;
      return onChange(checked, e);
    };
    return (
      <RadioLabel
        className={className}
        disabled={disabled}
        doubleLine={!!title}
        hideCircle={hideCircle}
      >
        <HiddenRadio
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        {!hideCircle && (
          <StyledRadio doubleLine={!!title} checked={checked}>
            <g fill="none">
              <circle stroke="#7C60D9" id="outer" cx="7" cy="7" r="6.5" />
              <circle fill="#7C60D9" id="inner" cx="7" cy="7" r="5" />
            </g>
          </StyledRadio>
        )}
        {!!icon && <IconContainer>{icon}</IconContainer>}
        {!!title && (
          <RadioTitle
            checked={checked}
            headerFont={hideCircle}
            aria-label={`checkbox with value ${title}`}
            role="button"
            tabIndex={0}
          >
            {title}
          </RadioTitle>
        )}
        <RadioContent
          doubleLine={!!title}
          bold={hideCircle || (!title && checked)}
        >
          {children}
        </RadioContent>
      </RadioLabel>
    );
  })({ ...props, ref })
);
