import { useContext } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import { useMediaQuery } from "hooks";

export const PreCampaign = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const isMobile = useMediaQuery();
  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const {
    background: preCampaignBackground,
    heading,
    link,
  } = event.preCampaign;

  let backgroundDesktop = `${api}/${storageDirectory}/${preCampaignBackground?.desktop}?alt=media`;
  let backgroundMobile = `${api}/${storageDirectory}/${preCampaignBackground?.mobile}?alt=media`;
  let logoImg = `${api}/${storageDirectory}/${preCampaignBackground?.logo}?alt=media`;
  let comingSoon = `${api}/${storageDirectory}/${preCampaignBackground?.comingSoon}?alt=media`;

  return (
    <Layout preCampaign flexGrow showFooter>
      <PageContent
        isMobile={isMobile}
        backgroundDesktop={backgroundDesktop}
        backgroundMobile={backgroundMobile}
      >
        <Logo
          isMobile={isMobile}
          src={logoImg}
          alt="us cellular company logo"
          aria-label="Link opens in a new tab to US Cellular homepage"
          onClick={() => {
            linkClickHandler(link, "USC Homepage", "header");
            window.open(link);
          }}
        />
        <Headline
          isMobile={isMobile}
          src={comingSoon}
          alt="coming soon headline"
        />
        <Pattern
          isMobile={isMobile}
          src={isMobile ? backgroundMobile : backgroundDesktop}
          alt="usc company colors"
        />
      </PageContent>
    </Layout>
  );
};

const PageContent = styled(MaxWidthContainer)`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 100px;
  justify-content: center;
  text-align: center;
  color: #0731a1;
  ${(props) =>
    props.isMobile &&
    css`
      padding-left: 0px;
    `}

  h2 {
    font-size: 60px;
    margin: 50px 0;
    font-weight: 800 !important;
  }
`;
const Logo = styled.img`
  position: absolute;
  top: 60px;
  left: 100px;
  width: 200px;
  ${(props) =>
    props.isMobile &&
    css`
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    `}
`;
const Headline = styled.img`
  position: absolute;
  top: auto;
  bottom: auto;
  left: 100px;
  width: 320px;
  ${(props) =>
    props.isMobile &&
    css`
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    `}
`;
const Pattern = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  ${(props) =>
    props.isMobile &&
    css`
      width: 100%;
      height: unset;
      bottom: -1%;
      left: 0;
      top: unset;
      right: unset;
    `}
`;
