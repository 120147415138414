import styled from "styled-components";

export const Checkbox = ({
  onChange,
  title,
  checked,
  disabled,
  hideCheck = false,
  error,
  ...props
}) => {
  const handleChange = (e) => {
    const {
      target: { checked },
    } = e;
    return onChange(checked, e);
  };
  return (
    <Wrapper>
      <CheckboxContainer>
        <HiddenCheckbox
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        {!hideCheck && (
          <StyledCheckbox checked={checked} error={error}>
            <Icon viewBox="0 0 8 8">
              <rect width="8" height="8" rx="4" fill="#4d4d4d" />
            </Icon>
          </StyledCheckbox>
        )}
      </CheckboxContainer>
      {!!title && (
        <StyledRadioTitle
          aria-label={`checkbox with value ${title}`}
          role="button"
          tabIndex={0}
        >
          {title}
        </StyledRadioTitle>
      )}
    </Wrapper>
  );
};

const primaryColor = "#4D4D4D";
const borderRadius = "100%";
const errorBackground = "#EDB8B4";
const errorBorderColor = "#E45C52";

const Wrapper = styled.label`
  display: flex;
  padding: 0;
`;

const CheckboxContainer = styled.div`
  margin: 0 10px 0 0;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const Icon = styled.svg``;

const StyledRadioTitle = styled.span`
  font-size: 8px;
  line-height: 1.5;
  padding-right: 25px;
  font-family: "AntennaRegular";
  color: ${primaryColor};

  a {
    color: ${primaryColor};
  }
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.checked ? "#ffffff" : "#ffffff")};
  border: 1px solid ${primaryColor};
  border-radius: ${borderRadius};
  width: 16px;
  height: 16px;

  input[disabled] + & {
    background: #dcdcdc;
    border-color: #dcdcdc;
  }

  ${Icon} {
    width: 8px;
    height: 8px;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};

    path {
      fill: ${primaryColor};
    }
  }

  ${(props) =>
    props.error &&
    `border: 2px solid ${errorBorderColor};
    background: ${errorBackground};`}
`;
