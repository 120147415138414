import { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    brand: brandLogo,
    // event: eventLogo,
    // secondary: secondaryLogo,
  } = event.logos;

  let brandLogoImg = `${api}/${storageDirectory}/${brandLogo?.image}?alt=media`;

  const logoClickHandler = (link) => {
    logClick({
      label: "Header Logo",
      url: link,
    });
  };

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper as="header">
          <HeaderContainer>
            <Link
              aria-label="button to open link in a new window to read US Cellular Privacy Policy"
              href={brandLogo.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                linkClickHandler(brandLogo.link, "header logo", "external")
              }
            >
              {brandLogoImg && (
                <PrimaryLogo
                  src={brandLogoImg}
                  alt="blue and red USCellular logo"
                />
              )}
            </Link>
            <SkipLink
              href="#main"
              aria-label="link to skip to main content"
              style={{ color: "#fff !important" }}
            >
              Skip to main content
            </SkipLink>
            <Link
              aria-label="button to open link in a new window to read about Life in us by US Cellular"
              href="https://www.uscellular.com/USMode"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                linkClickHandler(brandLogo.link, "header logo", "external")
              }
            >
              <p>What is US Mode?</p>
            </Link>
          </HeaderContainer>
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;
  padding: 0 35px;
  width: 100%;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0 25px;
  }
`;

const HeaderContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 5px 0;
  position: relative;
  & > p {
    white-space: nowrap;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
  }
`;

const Link = styled.a`
  font-family: "BrandonBld" !important;
  color: #0f359b;
  z-index: 2;
`;
const SkipLink = styled.a`
  color: #fff !important;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  &:visited,
  &:active {
    color: #fff !important;
  }
`;

const Logo = styled.img`
  max-width: 120px;
  width: 100%;
  display: flex;
`;

const PrimaryLogo = styled(Logo)``;
